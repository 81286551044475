import React from "react"
import cx from 'classnames';
import { transformText } from "../../utils/text.util";
import "./homeSubtitleUnderline.scss"

const HomeSubtitleUnderline = (props) => {
  const {
    children,
    left,
    upperCase,
    withoutTransform,
    withoutLine,
    className
  } = props;

  const renderText = () => {
    const text = withoutTransform ? children : transformText(children, "textAccent");
    return <h2 className={cx(className && className, 'homeSubtitleUnderline_text', upperCase && 'homeSubtitleUnderline_text__upperCase')}>{text}</h2>;
  }
  
  return (
    <div className={cx('homeSubtitleUnderline', left && 'homeSubtitleUnderline__left')}>
      {renderText()}
      {withoutLine ? null : <div className="homeSubtitleUnderline_line"></div>}
    </div>
  )
}

export default HomeSubtitleUnderline