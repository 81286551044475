import React from "react"
import HomeSubtitleUnderline from "../../home-subtitle-underline/HomeSubtitleUnderline"
import "./benefits.scss"

const Benefits = props => {
  const { list, subtitle, title, newTitle } = props

  return (
    <section className="benefits">
      {newTitle
        ? <HomeSubtitleUnderline>{newTitle?.richText[0]}</HomeSubtitleUnderline>
        : <h2 className="benefits_title">{title.text}</h2>
      }
      <p className="benefits_subtitle">{subtitle}</p>
      <section className="benefits_list">
        {list.map((item, index) => (
          <div key={`benefit-${index}`} className="item">
            {item.benefit_icon.url &&
              <figure className="item_icon" style={{ backgroundImage: `url(${item.benefit_icon.url})` }} />
            }
            <h3 className="item_name">{item.benefit_name}</h3>
            <div className="item_line"></div>
            <p className="item_description">
              {item.benefit_description}
            </p>
          </div>
        ))}
      </section>
    </section>
  )
}

export default Benefits
