import React, { useContext, useEffect, useState } from "react"
import { graphql } from "gatsby"
import LanguageContext from "../contexts/language"
import Footer from "../components/footer"
import Seo from "../components/seo"
import Layout from "../components/layout"
import HeaderWhite from "../components/HeaderWhite/HeaderWhite"
import Benefits from "../components/productDetail/benefits/Benefits"

const isBrowser = typeof window !== "undefined"

const JobDetails = props => {
  const { data } = props.data.prismicJobDetails

  const { theme, accesibilitySize } = useContext(LanguageContext)
  const [heightBanner, setHeightBanner] = useState(null)
  const [themeState, setThemeState] = useState(theme)
  const [accesibilitySizeState, setAccesibilitySizeState] = useState(
    accesibilitySize
  )

  useEffect(() => {
    if (isBrowser && themeState) localStorage.setItem("foris_theme", themeState)
  }, [themeState])

  useEffect(() => {
    if (isBrowser && accesibilitySizeState)
      localStorage.setItem("accesibility_size", accesibilitySizeState)
  }, [accesibilitySizeState])

  return (
    <Layout
      {...props}
      theme={themeState}
      accesibilitySize={accesibilitySizeState}
    >
      <Seo title={data.seo_title} desc={data.seo_description} />
      <HeaderWhite
        heightBanner={heightBanner}
        onclick={() =>
          themeState === "theme-light"
            ? setThemeState("theme-dark")
            : setThemeState("theme-light")
        }
        onClickPlus={() => {
          if (accesibilitySizeState < 2) {
            setAccesibilitySizeState(accesibilitySizeState + 1)
          }
        }}
        onClickMinus={() => {
          if (accesibilitySizeState => 2) {
            setAccesibilitySizeState(accesibilitySizeState - 1)
          }
        }}
      />
      <main id="app" className="jobDetails" role="main">
        <section className="jobDetails_hero">
          <article className="jobDetailsBanner">
            <h1 className="jobDetailsBanner_title">
              {data.position_title.text}
            </h1>
            <p className="jobDetailsBanner_summary">{data.position_summary}</p>
          </article>
        </section>
        <section className="jobDetails_description">
          {data.position_description.map(description => (
            <article className="descriptionDetail">
              <h2 className="descriptionDetail_subtitle">
                {description.description_subtitle.text}
              </h2>
              {description.description_content && (
                <p className="descriptionDetail_text">
                  {description.description_content}
                </p>
              )}
              {description.description_items &&
                description.description_items.richText && (
                  <ul className="descriptionDetail_list">
                    {description.description_items.richText.map(
                      (item, index) => (
                        <li className="listItem" key={`item-${index}`}>
                          {item.text}
                        </li>
                      )
                    )}
                  </ul>
                )}
            </article>
          ))}
        </section>
        {data.body.length > 0 &&
          data.body.map(section => {
            switch (section.slice_type) {
              case "benefits":
                const benefits = section
                return (
                  <section className="jobDetails_benefits">
                    <Benefits
                      title={benefits.primary.benefit_title}
                      subtitle={benefits.primary.benefit_subtitle}
                      list={benefits.items}
                    />
                  </section>
                )
              default:
                break
            }
          })}
        <section className="jobDetails_contact">
          <article className="contactContent">
            <h2 className="contactContent_subtitle">
              {data.job_contact_label.text}
            </h2>
            <a
              className="contactContent_button"
              href={`mailto: ${data.job_contact_email}`}
            >
              {data.job_contact_email}
            </a>
          </article>
        </section>
      </main>
      <Footer />
    </Layout>
  )
}

export default JobDetails

export const pageQuery = graphql`
  query JobDetails($lang: String!, $uid: String!) {
    prismicJobDetails(lang: { eq: $lang }, uid: { eq: $uid }) {
      uid
      data {
        position_title {
          text
        }
        position_summary
        position_description {
          description_subtitle {
            text
          }
          description_content
          description_items {
            richText
          }
        }
        body {
          ... on PrismicJobDetailsDataBodyBenefits {
            id
            slice_type
            primary {
              benefit_title {
                text
              }
            }
            items {
              benefit_icon {
                alt
                url
              }
              benefit_name
              benefit_description
            }
          }
        }
        job_contact_label {
          text
        }
        job_contact_email
      }
    }
  }
`
